<template>
<div class="bg-default">
    <header class="header">
        <div class="header-left">
            <a href="#">
                <img class="header-settings-icon" src="/img/settings-icon.png" alt="Settings" />
            </a>
        </div>
        <div class="header-center"></div>
        <div class="header-right">
            <a @click="exit()">
                <img class="header-settings-icon" src="/img/exit-icon.png" alt="Exit" />
            </a>
        </div>
    </header>
    <main class="main">
        <div class="container">
            <div class="card card-800 card-centerred">
                <div class="card-logo">
                    <img src="/img/tozsdecapa_logo.png" alt="Tőzsdecápa logo" />
                </div>
                <div class="card-main-title">
                    <!-- <h1>Tőzsdecápa</h1> -->
                    <h1>{{ $t('idle-title') }}</h1>
                </div>
                <div class="card-main-message">
                    <div class="card-main-message-img">
                        <img src="/img/card_placement_icon.png" alt="Place card here" />
                    </div>
                    <div class="card-main-message-text">
                        <!-- A kutatás indításához érintsd a kártyát az érzékelőhöz. -->
                        {{ $t('message') }}
                    </div>
                </div>
            </div>
        </div>
    </main>
    <footer class="footer">
        <div class="container">
            <div class="flags">
                <div @click="chooseLanguage('hu')" :class="[language === 'hu' ? 'flags-flag flags-flag-active' : 'flags-flag']">
                    <a>
                        <img src="/img/hungary-flag-icon.png" alt="Hungary" />
                        <div class="flags-flag-name">Magyar</div>
                    </a>
                </div>
                <div @click="chooseLanguage('en')" :class="[language === 'en' ? 'flags-flag flags-flag-active' : 'flags-flag']">
                    <a>
                        <img src="/img/uk-flag-icon.png" alt="United Kingdom" />
                        <div class="flags-flag-name">English</div>
                    </a>
                </div>
                <div @click="chooseLanguage('cn')" :class="[language === 'cn' ? 'flags-flag flags-flag-active' : 'flags-flag']">
                    <a>
                        <img src="/img/china-flag-icon.png" alt="China" />
                        <div class="flags-flag-name">中国人</div>
                    </a>
                </div>
            </div>
        </div>
    </footer>
</div>
</template>

<script>
import {
    mapGetters
} from "vuex";

export default {

    name: "Idle",
    computed: {
        ...mapGetters([
            'language',
        ]),
    },
    created() {
        this.saveDeviceId();
    },
    mounted() {
        this.$store.commit("setRouteName", this.$route.name);
        console.log('this is lang: ', this.language);
    },
    methods: {
        saveDeviceId() {
            if (this.$route.params.id) {
                this.$store.commit("setDeviceId", this.$route.params.id);
                console.log("params", this.$route.params);
                //this.$store.commit('setIsGameStarted', false);
            }
        },
        chooseLanguage(language) {
            this.$store.commit('setLanguage', language);
            this.$store.dispatch('clearInt');
        }
    },
};
</script>
